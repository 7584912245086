import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import _ from '../../lodash-wrapper';
import classNames from 'classnames/bind';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player/youtube';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider'
import DOMPurify from 'dompurify';

import { CourseDetails } from '../../models/course';
import { CourseGroupDetails } from '../../models/courseGroup';
import { convertEnumDisplayName, datetimeFormat } from '../../utils';
import { CourseCategory, CourseLevel, CourseLanguage } from '../../models/enums';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DATETIME_DISPLAY_FORMAT, DATETIME_DISPLAY_MULTILINE_FORMAT } from '../../constants';

// import BannerImage from '../shared/bannerImage';
// import PromotionalVideo from '../shared/promotionalVideo';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        padding: '20px 0px 10px 0px',
        textAlign: 'justify',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        maxWidth: '100%',
    },
    categoryContainer: {
        alignContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center', // Ensures items are aligned to the center vertically
        flexWrap: 'wrap', // Add this to wrap if needed
    },
    categoryBox: {
        display: 'flex',
        minWidth: '45px',
        fontWeight: 'bold',
        marginRight: '8px', // Add some margin to separate items
    },
    categoriesContainer: { // Adjusted style for categories container
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    categoriesItem: {
        display: 'inline', // Ensures categories are displayed inline
    },
    contentContainer: {
        padding: '30px 0px 0px 0px',
        overflowWrap: 'break-word',
        wordBreak: 'break-word',
        maxWidth: '100%', 
    },
    basicInfoContainer: {
        padding: '0px',
    },
    contentText: {
        ...theme.text.content,
        color: '#707070',
        lineHeight: '30px',
        overflowWrap: 'anywhere',
        wordBreak: 'break-word',
        marginRight: '0px',
    },
    contentTitle: {
        cursor: 'pointer', 
        color: '#174C81', 
        fontSize: '20px', 
        fontWeight: 600, 
        marginTop: '20px',
        marginBottom: '20px'
    },
    videoPlayerContainer: {
        marginTop: '20px',
        width: '100%',
        maxWidth: '86vw',
        aspectRatio: '16/9',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    sectionTitle: {
        color: '#000000',
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: '10px',
    },
    sessionTitleMobile: {
        color: theme.palette.text.primary,
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: '10px',
    },
    sessionTimeMobile: {
        ...theme.text.content,
        color: theme.palette.text.primary,
        fontWeight: 400,
        marginBottom: '10px',
        marginLeft: '15px',
    },
    tableHeading: {
        ...theme.text.content,
        whiteSpace: 'nowrap',
    },
    sessionTimeColumn: {
        whiteSpace: 'pre',
    },
    sessionMaterialColumn: {
        maxWidth: '400px',
        paddingRight: '10px'
    },
    tableContent: {
        ...theme.text.content,
    },
    tableContentMaterial: {
        ...theme.text.content,
        paddingLeft: 0,
    },
    materialListItem: {
        marginBottom: '5px',
    }
}));

const renderBasicInfo = (classes, target) => {
    return (
        <Container className={classes.basicInfoContainer}>

            <Typography className={classNames(classes.contentText)}>
                <Box className={classes.categoryContainer}>
                    <Box className={classes.categoryBox} >
                        範疇：
                    </Box>
                    <Box className={classes.categoriesContainer}> {/* Flexbox container for categories */}
                        {target.categories.map((category, index) => (
                            <span key={index} className={classes.categoriesItem}>
                                {convertEnumDisplayName(category, CourseCategory)}
                                {index < target.categories.length - 1 ? '/ ' : ''}
                            </span>
                        ))}
                    </Box>
                </Box>
            </Typography>

            <Typography className={classes.contentText}>
                <Box className={classes.categoryContainer}>
                    <Box className={classes.categoryBox} >程度：</Box><Box>{convertEnumDisplayName(target.courseLevels, CourseLevel)}</Box>
                </Box>
            </Typography>

            <Typography className={classes.contentText}>
                <Box className={classes.categoryContainer}> 
                    <Box className={classes.categoryBox} >語言：</Box><Box>{convertEnumDisplayName(target.language || target.languages, CourseLanguage)}</Box>
                </Box>
            </Typography>
        </Container>
    );
}

const PromotionalVideo = props => {
    const classes = useStyles(useTheme());
    const { target } = props;
    const hasVideo =  _.size(target.promotionalVideoUrl) > 0;
    const ref = useRef();
    const setRef = useCallback(node => {
        ref.current = node;
    }, []);

    if(!hasVideo)
        return null;
    
    return (
        <>
        {/*<Chip 
            style={{
                backgroundColor: '#F4DFE9',
                color: '#4E457C',
                fontSize: '12px',
                fontWeight: 600,
            }}
            label='免費影片課程試看(無須登入)'
        />*/}
        <div ref={setRef} className={classes.videoPlayerContainer}>
            <ReactPlayer
                url={target.promotionalVideoUrl}
                width='100%'
                height='100%'
                //height={ref.current?`${ref.current.clientWidth/16*9}px`:'30px'}
            />
        </div>
        </>
    );
}
PromotionalVideo.propTypes = {
    target: PropTypes.object.isRequired,
}

const renderTextContentSection = (classes, title, content) => {
    return (
        <Container className={classes.contentContainer}>
            <Typography className={classes.sectionTitle} >
                {title}
            </Typography>
            <div
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} 
                className={classes.contentText}
            />
        </Container>
    );
}

const renderMaterial = (classes, material) => {
    let title = (
        <Typography className={classes.tableContentMaterial}>{material.title}</Typography>
    );
    if(material.file)
        title = (
            <Link href={material.file}>{title}</Link>
        );
    return (
        <li key={material.id} className={classes.materialListItem}>
            {title}
        </li>
    )
}

const getEmptySessionCaption = renderCourse => {
    if(renderCourse)
        return '暫時未有相關資訊';
    return '暫時此課程/講座未可報讀，故不顯示目錄';
}
const renderSessionsTable = (classes, renderCourse, sessions) => {
    const sessionsMaterialsCount = _.map(sessions, s => _.size(s.materials));
    const totalMaterials = _.reduce(sessionsMaterialsCount, (total, size) => total + size); 
    return (
        <Container className={classes.contentContainer}>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                {_.size(sessions) === 0 && <caption>{getEmptySessionCaption(renderCourse)}</caption> }
                <TableHead>
                <TableRow>
                    <TableCell className={classNames(classes.sessionTimeColumn, classes.tableHeading)}>{renderCourse? '上堂時間': '堂次'}</TableCell>
                    <TableCell className={classNames(classes.sessionTitleColumn, classes.tableHeading)}>題目</TableCell>
                    <TableCell className={classNames(classes.sessionDescriptionColumn, classes.tableHeading)}>簡介</TableCell>
                    {totalMaterials > 0 && <TableCell className={classNames(classes.sessionMaterialColumn, classes.tableHeading)}>參考</TableCell>}
                </TableRow>
                </TableHead>
                <TableBody>
                {sessions.map((session, idx) => (
                    <TableRow key={session.id}>
                        <TableCell className={classNames(classes.sessionTimeColumn, classes.tableContent)}>
                            {renderCourse
                                ?`${datetimeFormat(session.time, DATETIME_DISPLAY_MULTILINE_FORMAT)}\n(${session.durationMinute} 分鐘)`
                                :`${idx+1}`
                            }
                        </TableCell>
                        <TableCell className={classes.tableContent}>
                            {session.title}
                        </TableCell>
                        <TableCell 
                            style={{whiteSpace: 'pre-line'}}
                            className={classes.tableContent}
                        >
                            {_.size(session.description)?session.description:' / '}
                        </TableCell>
                        {totalMaterials > 0 &&
                        <TableCell className={classNames(classes.sessionMaterialColumn, classes.tableContentMaterial)}>
                            <ul>
                                {session.materials.map(m => renderMaterial(classes, m))}
                            </ul>
                        </TableCell>
                        }
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Container>
    );
}

const renderSessionsForMobile = (classes, renderCourse, sessions) => {
    return (
        <Container className={classes.contentContainer}>
            {_.size(sessions) === 0 && 
                <Typography className={classes.contentText}>
                    {getEmptySessionCaption(renderCourse) }
                </Typography>
            }
            {sessions.map((session, idx) => (
                <div key={session.id}>
                    <Typography display='inline' className={classes.sessionTitleMobile}>
                        {`${idx+1}. ${session.title}`}
                    </Typography>
                    {renderCourse &&
                    <Typography display='inline' className={classes.sessionTimeMobile}>
                        ({datetimeFormat(session.time, DATETIME_DISPLAY_FORMAT)})
                    </Typography>}
                    <Typography
                        className={classes.contentText}
                        style={{whiteSpace: 'pre-line'}}
                    >
                        {session.description}
                    </Typography>
                    <ul>
                        {session.materials.map(m => renderMaterial(classes, m))}
                    </ul>
                </div>
            ))}
        </Container>
    );
}

const renderUnassignedMaterialsSection = (classes, materials) => {
    return (
        <Container className={classes.contentContainer}>
            <Typography className={classes.sectionTitle} >
                參考附件
            </Typography>
            {_.map(materials, m => renderMaterial(classes, m))}
        </Container>
    );
}

const DetailsSection = props => {
    const { target } = props;
    const theme = useTheme();
    const classes = useStyles(theme);
    const desktopVersion = useMediaQuery(theme.breakpoints.up('md'));
    const sessions = target.sessions || target.sampleSessions;    
    const hasVideo =  _.size(target.promotionalVideoUrl) > 0;

    // State to manage the visibility of the sessions table
    const [openStateTitle, setOpenStateTitle] = useState(false);
    
    // Function to toggle the table visibility
    const toggleTitleCollapse = () => {
        setOpenStateTitle(prevState => !prevState);
    };

    return (
        <Container className={classes.mainContainer}>
            {renderBasicInfo(classes, target)}
            {renderTextContentSection(classes, '簡介', target.description)}
            {renderTextContentSection(classes, '課程對象', target.targetAudience)}
            {renderTextContentSection(classes, '學習成果', target.learningOutcome)}            
            <Divider style={{ marginTop: "30px"}}/>

            {/* "Click to Open" Button Styled Like FAQ */}
            <Typography onClick={() => toggleTitleCollapse()} className={classes.contentTitle} >
            目錄
                {<ExpandMoreIcon 
                    style={{ verticalAlign: "middle",
                    transform: openStateTitle ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }} 
                />}
            </Typography>

            {/* Collapsible Section for sessionsTable */}
            <Collapse in={openStateTitle}>
                {desktopVersion
                ?renderSessionsTable(classes, target instanceof CourseDetails && !target.everlasting, sessions)
                :renderSessionsForMobile(classes, target instanceof CourseDetails && !target.everlasting, sessions)
                }
            </Collapse>
            <Divider style={{ marginTop: "10px", marginBottom: "20px"}}/>

            {/* "Show promotional video */}
            {hasVideo && 
                <Grid> 
                    <PromotionalVideo target={target} /> 
                    <Divider style={{ marginTop: "40px", marginBottom: "30px"}}/> 
                </Grid>
            }

            {_.size(target.unassignedMaterials) > 0 && renderUnassignedMaterialsSection(classes, target.unassignedMaterials)}
        </Container>
    );
}
DetailsSection.propTypes = {
    target: PropTypes.oneOfType([
        PropTypes.instanceOf(CourseDetails).isRequired,
        PropTypes.instanceOf(CourseGroupDetails).isRequired,
    ])
}
export default DetailsSection;
